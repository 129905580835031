'use client';

import React, { useEffect } from 'react';

import { redirect } from 'next/navigation';

import { useLanguage } from '@lib/hooks';

type GlobalErrorProps = {
  error: Error;
  reset: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const GlobalError: React.FC<GlobalErrorProps> = ({ error, reset }) => {
  const lng = useLanguage();

  useEffect(() => {
    redirect(`/${lng}/error`);
  }, [lng]);

  return null;
};

export default GlobalError;
